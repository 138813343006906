<template>
  <div class="Sidebar">
    <div class="Sidebar__column">
      <img src="@/assets/img/logotype.svg" class="Sidebar__logo" />
      <div class="Sidebar__list">
        <div
          class="Sidebar__list-parent"
          @click="isSuperUsersActive = !isSuperUsersActive"
        >
          SuperUsers
          <img
            :class="{ 'Sidebar__list-arrow--active': isSuperUsersActive }"
            src="@/assets/svg/small-arrow.svg"
            class="Sidebar__list-arrow"
          />
        </div>
        <div
          v-if="isSuperUsersActive"
          :class="{
            'Sidebar__list-child--active':
              $route.name === 'superuserExpiration',
          }"
          class="Sidebar__list-child"
          @click="routerView('superuserExpiration')"
        >
          SuperUser Expiration
        </div>
        <div
          v-if="isSuperUsersActive"
          :class="{
            'Sidebar__list-child--active': $route.name === 'superuserCoupons',
          }"
          class="Sidebar__list-child"
          @click="routerView('superuserCoupons')"
        >
          SuperUser Coupons
        </div>
        <div class="Sidebar__line" />
        <div
          class="Sidebar__list-parent"
          @click="isBanReportsActive = !isBanReportsActive"
        >
          Ban & Reports
          <img
            :class="{ 'Sidebar__list-arrow--active': isBanReportsActive }"
            src="@/assets/svg/small-arrow.svg"
            class="Sidebar__list-arrow"
          />
        </div>
        <div
          v-if="isBanReportsActive"
          :class="{ 'Sidebar__list-child--active': $route.name === 'banUser' }"
          class="Sidebar__list-child"
          @click="routerView('banUser')"
        >
          Ban/Unban User
        </div>
        <div
          v-if="isBanReportsActive"
          :class="{ 'Sidebar__list-child--active': $route.name === 'banWords' }"
          class="Sidebar__list-child"
          @click="routerView('banWord')"
        >
          Ban/Unban Words
        </div>
        <div
          v-if="isBanReportsActive"
          :class="{ 'Sidebar__list-child--active': $route.name === 'nsfw' }"
          class="Sidebar__list-child"
          @click="routerView('nsfw')"
        >
          Unsafe content
        </div>
        <div
          v-if="isBanReportsActive"
          :class="{
            'Sidebar__list-child--active': $route.name === 'removePost',
          }"
          class="Sidebar__list-child"
          @click="routerView('removePost')"
        >
          Remove post
        </div>
        <div class="Sidebar__line" />
        <div
          :class="{ 'Sidebar__list-parent--active': $route.name === 'search' }"
          class="Sidebar__list-parent"
          @click="routerView('search')"
        >
          Search User
        </div>
        <div class="Sidebar__line" />
        <div
          :class="{ 'Sidebar__list-parent--active': $route.name === 'moreTab' }"
          class="Sidebar__list-parent"
          @click="routerView('sections')"
        >
          More Tab
        </div>
        <div class="Sidebar__line" />
        <div
          :class="{ 'Sidebar__list-parent--active': $route.name === 'tags' }"
          class="Sidebar__list-parent"
          @click="routerView('tags')"
        >
          Tags
        </div>
        <div class="Sidebar__line" />
        <div
          :class="{
            'Sidebar__list-parent--active': $route.name === 'openBroadcasts',
          }"
          class="Sidebar__list-parent"
          @click="routerView('openBroadcasts')"
        >
          Open Broadcasts
        </div>
        <div class="Sidebar__line" />
        <div
          :class="{
            'Sidebar__list-parent--active': $route.name === 'chatBackgrounds',
          }"
          class="Sidebar__list-parent"
          @click="routerView('chatBackgrounds')"
        >
          Chat backgrounds
        </div>
        <div class="Sidebar__line" />
        <div
          class="Sidebar__list-parent"
          @click="isKingsPayActive = !isKingsPayActive"
        >
          KingsPay
          <img
            :class="{ 'Sidebar__list-arrow--active': isKingsPayActive }"
            src="@/assets/svg/small-arrow.svg"
            class="Sidebar__list-arrow"
          />
        </div>
        <div
          v-if="isKingsPayActive"
          :class="{
            'Sidebar__list-child--active': $route.name === 'wallets',
          }"
          class="Sidebar__list-child"
          @click="routerView('wallets')"
        >
          Wallets
        </div>
        <div class="Sidebar__line" />
        <div
          class="Sidebar__list-parent"
          @click="isDevelopersActive = !isDevelopersActive"
        >
          Developers
          <img
            :class="{ 'Sidebar__list-arrow--active': isDevelopersActive }"
            src="@/assets/svg/small-arrow.svg"
            class="Sidebar__list-arrow"
          />
        </div>
      </div>
      <div
        v-if="isDevelopersActive"
        :class="{
          'Sidebar__list-child--active': $route.name === 'developer',
        }"
        class="Sidebar__list-child"
        @click="routerView('developerUsersList')"
      >
        Developer Users
      </div>
      <div
        v-if="isDevelopersActive"
        :class="{
          'Sidebar__list-child--active': $route.name === 'applicationReview',
        }"
        class="Sidebar__list-child"
        @click="routerView('applicationReview')"
      >
        Application Review
      </div>
    </div>
    <div class="Sidebar__column">
      <div class="Sidebar__logout" @click="logout()">
        <img src="@/assets/svg/logout.svg" class="Sidebar__logout-icon" /> Log
        out
      </div>
      <div class="Sidebar__name">
        {{ admin.first_name }} {{ admin.last_name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Sidebar',

  data: () => ({
    isSuperUsersActive: false,
    isBanReportsActive: false,
    isKingsPayActive: false,
    isDevelopersActive: false,
  }),

  created() {
    console.log(this.$route.name);
  },

  computed: {
    ...mapGetters(['admin']),
  },

  methods: {
    routerView(viewName) {
      this.$router.push({ name: viewName }).catch(() => {});
      this.$emit('close-nav');
    },
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/stylesheets/components/common/navigation/Sidebar.scss';
</style>
